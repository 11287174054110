<template>
    <v-container>
        <h1>Contact Us</h1>
        <p>Questions? Suggestions? Concerns? Reach out to the Crow.tools team below.</p>

        <v-form v-model="valid">
            <v-text-field 
                v-model="guest.name" 
                label="Your Name" 
                outlined
                :rules="[required('Name')]"/>
            <v-text-field 
                v-model="guest.email" 
                label="Your Email" 
                outlined
                :rules="[required('Email'), emailFormat()]"/>

            <v-textarea 
                v-model="message" 
                label="Your Message" 
                auto-grow 
                outlined 
                :rules="[required('A message')]"/>
                
            <v-card v-if="sent" color="success" class="mb-5">
                <v-card-text>
                    Your message has been sent!
                </v-card-text>
            </v-card>

            <v-card v-if="error" color="error" class="mb-5">
                <v-card-text>
                    Sorry, your message could not be sent. Please try again later.
                </v-card-text>
            </v-card>

            <v-btn 
                color="primary" 
                @click="sendMessage"
                :disabled="!valid">
                Send
            </v-btn>
        </v-form>

        

    </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import validations from "@/utils/validations";
import Api from "@/services/api";

export default {
    data: () => ({
        message: "",
        valid: false,
        ...validations,
        sent: false,
        error: false
    }),
    computed: {
        ...mapFields('guest', ['guest']),
    },
    methods: {
        sendMessage: function() {
            this.$store.dispatch('guest/save');
            Api().post('/contact', {
                guest: this.guest,
                message: this.message
            }).then(() => {
                this.sent = true;
                this.valid = false;
            }).catch(() => {
                this.error = true;
            })
        }
    }
}
</script>

<style>

</style>